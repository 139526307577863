<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #link1>
          <router-link to="/blog"> 
            <u>Blog</u>>
          </router-link>
        </template>
        <template #current>
          Почему стоит открыть бизнес во Франции
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
          <PostHeader>
            <template #title>
                Почему стоит открыть бизнес во Франции <br>
                Секреты получения поддержки от государства.
            </template>
            <template #date>
                11.05.2021
            </template>
          </PostHeader>

          <PostBodySection>
            <template #title>
              Мифы о том, как на Францию повлияла эпидемия Ковид-19. 
            </template>
            <template #content>
                <p>
                    Франция является пятой по величине экономикой мира после США, Китая, Японии и Германии и соответственно второй в Европе, уступая лишь немецкой. Несмотря на кризис, в первой половине 2021 года рост экономики страны составил 0,3%. По сравнению со своими соседями это достаточно положительная динамика. При этом во Франции важна роль государства, в этой сфере работает четверть населения страны. Что касается ВВП, то самая большая его доля приходится на сферу услуг - около 68%. 
                </p>
            </template>
          </PostBodySection>
        
          <PostBodySection>
            <template #title>
              Франция - страна, которая поощряет предпринимательство.
            </template>
            <template #content>
                <p>
                    Франция заняла третье место в рейтинге Global Entrepreneurship Index 2017 - здесь именно та предпринимательская среда, которая стремится облегчить жизнь предпринимателям. В 2016 году на создание компании во Франции потребовалось всего 3,5 дня по сравнению с 4,5 днями в Великобритании и 10,5 днями в Германии. Согласно опросам, более трети французов заявили, что хотели бы открыть собственное дело.
                </p>
                <p>
                    Что касается коррупции во Франции, то здесь с ней борются достаточно жестко. Известное дело бывшего президента Франции Николя Саркози подтверждает тот факт, что от правосудия не застрахованы даже высокопоставленные лица. Таким образом, открывая бизнес во Франции, можно быть уверенным в том, что все будет проходить в рамках закона. Несмотря на высокие налоги, Франция является одной из самых привлекательных стран мира в плане открытия бизнеса. Для самых востребованных отраслей, например IT, есть особые льготные правила. К тому же, во Франции настолько развит дух предпринимательства, что здесь получить средства на инвестирование собственного бизнеса, бывает проще, чем в ряде других странах. Для этого нужно качественно оформить свой проект и представить его перед инвесторами и бизнес-ангелами.
                </p>
                <p>
                    Во Франции ежедневно организуется огромное количество мероприятий среди владельцев бизнеса, стартап-ов, инвесторов, бизнес-ангелов. Самые известные французские стартапы, получившие многомилионное финансирование и вышедшие на мировой рынок, это BlaBlaCar, Doctorlib, Citeo и многие другие. Большое количество бизнес-инкубаторов во Франции дают возможность вновь основанным стартапам получить наставления и советы, а также создать профессиональные сети. В 2017 году Станция F, крупнейший в мире инкубатор на 1000 стартапов, открыл свои двери в Париже.
                </p>
                <p>
                    С тех пор как французское правительство поставило предпринимательство во главу угла, создание бизнеса во Франции стало особенно важным. В 2017 году было основано более 594 500 фирм, это один из самых высоких показателей роста в странах Европейского Союза. Предпринимательство во Франции находится на подъеме. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Последствия эпидемии Ковид-19 для экономики Франции.
            </template>
            <template #content>
                <p>
                    Миф о том, что предприниматели в странах Европы жестко пострадали от последствий эпидемии, появился из-за того, что информационные источники ряда стран нацелены на то, чтобы преподносить информацию в выгодном им свете. Люди, непосредственно живущие во Франции и получающие поддержку от государства, могут с легкостью его опровергнуть.
                </p>
                <p>
                    Во время эпидемии Ковид, государство взяло на себя обязательства по выплате пособий работникам, не имеющим возможности вести деятельность, а также поддерживало бизнес специальными ежемесячными выплатами. Например, во время второй волны эпидемии, рестораны во Франции были закрыты с ноября 2020 по середину мая 2021 года. При этом все сотрудники получали пособие от государства, которое было лишь чуть меньше, чем их ежемесячная зарплата.   Собственникам же выплачивались солидные пособия, которые зависели от ежегодного оборота компании. Благодаря этому многим фирмам удалось удержаться от банкротства. 
                </p>
                <p>
                    Тем не менее, как и во всем мире, не все фирмы продолжили свое существование после пандемии. Что для одних является поводом для пересмотра деятельности (большинство из тех, кто объявил банкротство, не справились с кредитами), для других является полем для открытия деятельности. В результате банкротства, многие фирмы попадают на аукционы. По решению суда и в результате честной конкурентной борьбы, вы можете получить действующий бизнес по привлекательной цене. Что касается предпринимателей, которые остались без работы, здесь на помощь вновь приходит государство. Ежемесячные пособия по безработице  во Франции рассчитаны таким образом, что человек может спокойно покрывать свои базовые потребности в течение продолжительного времени - до тех пор, пока не возобновит трудовую деятельность. 
                </p>
                <p>
                    С тех пор как французское правительство поставило предпринимательство во главу угла, создание бизнеса во Франции стало особенно важным. В 2017 году было основано более 594 500 фирм, это один из самых высоких показателей роста в странах Европейского Союза. Предпринимательство во Франции находится на подъеме. 
                </p>
            </template>
          </PostBodySection>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost4',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
